import React from 'react';
import { Contacts, Details } from '../../components';

export default function (personal) {
  return (
    <Details title="Contacts">
      <Contacts {...personal} />
    </Details>
  );
}
