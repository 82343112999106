const data = {
  personal: {
    name: 'Maslovskyy Andriy',
    phone: '+380-95-310-80-65',
    email: 'maslovskiy2517@gmail.com',
    location: 'Lviv, Ukraine',
    summary: 'I am Software Engineer with more than 6 years of experience in software development. During this time, I have been involved in a number of commercial software projects as Front End Developer. I have good practical skills in development of web-oriented applications using JS (ES6), React and other front end technologies. Goal-oriented person, willing to study and good team player.',
  },
  education: {
    university: 'Ukrainian Academy of Printing',
    faculty: 'Department of Mechanical Engineering Specialist Degree',
    graduated: 2015,
  },
  skills: {
    core: ['React + Redux + Redux Saga', 'JavaScript ( ES6 ) / Typescript', 'HTML5', 'CSS3 ( SCSS modules, StyledComponents )'],
    lang: ['Ukrainian : Native', 'English : Upper Intermediate'],
    tools: ['Next.js', 'Storybook', 'Zustand', 'Leaflet', 'SSR', 'Webpack', 'Recompose', 'Npm/Yarn', 'Zeplin/Figma'],
  },
  experience: [
    {
      companyName: 'Globallogic',
      period: 'November 2022',
      projects: [
        {
          name: 'Internationally enterprise operating in the fields of optics and optoelectronics.',
          desc: 'Internationally leading technology enterprise operating in the fields of optics and optoelectronics.',
          stack: 'Typescript (ES6), React, Zustand, GraphQL, Storybook',
          role: 'Development of an internal UI library, for usage in a plenty of internal projects. Maintaining of existing codebase. Participation in discussions between back end and front end teams. Creating layouts and styles for components. Creating/modifying CRUD endpoints.',
        },
      ],
    },
    {
      companyName: 'Intellias',
      period: 'December 2021 - July 2022',
      projects: [
        {
          name: 'International Bank Platform',
          desc: 'International Bank Platform with full pack of tools and possibilities to trade, exchange currencies and cryptos.',
          stack: 'Javascript (ES6), React, Redux, Redux Thunk, Lodash, MomentJS',
          role: 'Development of a separate packages which are included into the main platform. Maintaining and updating existing packages. Participation in discussions between back end and front end teams. Creating layouts and styles for components.',
        },
      ],
    },
    {
      companyName: 'Eleks',
      period: 'August 2020 - December 2021',
      projects: [
        {
          name: 'Parcels Tracking',
          desc: 'Parcels tracking application, with the full tracking information for user.',
          stack: 'Javascript (ES6), React, Redux, Redux Thunk, Lodash, MomentJS, Webpack, Redux Form, Recompose, React Bootstrap, Redux ORM',
          role: "Creation of project architecture according to customer's requirements. Development of whole project from front end side. Participation in discussions between back end and front end teams. Creating layouts and styles for components.",
        },
      ],
    },
    {
      companyName: 'Lionwood Software',
      period: 'June 2019 - August 2020',
      projects: [
        {
          name: 'ICity',
          desc: 'Transport tracking app. Developed application has possibility to track transports in real time on the map, also create/modify existing data via admin panel.',
          stack: 'Javascript (ES6), React, Redux, Redux Saga, Lodash, Next.js, Server side rendering, Leaflet, React Leaflet, MomentJS, Webpack, HTML5, SCSS',
          role: "Creation of project architecture according to customer's requirements. Development of whole project from front end side. Participation in discussions between back end and front end teams. Creating layouts and styles for components.",
        },
        {
          name: 'Camden',
          desc: 'Application that shows users concerts and other events in one of London regions. Developed application allowed administrators to add, edit, delete events, artists and other stuff from original web site.',
          stack: 'Javascript (ES6), React, Redux, Redux Saga, Lodash, Next.js, Server side rendering , MomentJS, Webpack, HTML5, SCSS',
          role: "Creation of project architecture according to customer's requirements. Development of whole project from front end side. Participation in discussions between back end and front end teams. Creating layouts and styles for components. Direct communication with the costumer.",
        },
        {
          name: 'Alight',
          desc: "Company which provides light equipment for concerts and other big events. Developed application has possibility to track company's equipment and show all situation about tools and employees.",
          stack: 'Javascript (ES6), React, Redux, Redux Saga, Lodash, MomentJS, Webpack, HTML5, SCSS, Bootstrap',
          role: 'Creating layouts and styles for components. Participation in UI/UX discussions. User interface development. Participation in the processes of the development methodology.',
        },
      ],
    },
    {
      companyName: 'Upqode',
      period: 'November 2017 - May 2019',
      projects: [
        {
          name: 'MultiSport',
          desc: 'The company which owns a chain of gyms, provides an opportunity to book, check out the gym class, or find the nearest gym.',
          stack: 'React, Redux, SCSS, Javascript, Gulp, HTML5/CSS3, Bootstrap, Git, Zeplin, Lodash',
          role: 'Creating layout of the whole project. Participation in UI/UX discussions. User interface development. Creating components based on the site design.',
        },
        {
          name: 'Connections',
          desc: 'Connections specialized crisis and substance use treatment centers provide immediate access to treatment and careful transitions to the right level of ongoing care.',
          stack: 'Nunjucks, SCSS, Javascript, jQuery, Gulp, HTML5/CSS3, Bootstrap, WordPress, Git, Zeplin, Google API',
          role: 'Creating layout of the whole project. Developing of user interface.',
        },
        {
          name: 'Lextegrity',
          desc: 'The company which provides to enterprises services for preventing and detect financial crimes through the use of modern technology.',
          stack: 'Nunjucks, SCSS, Javascript, jQuery, Gulp, HTML5/CSS3, Bootstrap, WordPress, Git, Zeplin, Google API',
          role: 'Creating layout of the whole project. Developing of user interface.',
        },
        {
          name: 'IUP',
          desc: 'Japanese university website, which late check out the information about the university and also possibility to send enter application.',
          stack: 'Nunjucks, SCSS, Javascript, jQuery, Gulp, HTML5/CSS3, Bootstrap, WordPress, Git, Zeplin, Google API',
          role: "Creation of project architecture according to customer's requirements. Development of whole project from front end side. Creating layout of the whole project. Developing of user interface.",
        },
      ],
    },
  ],
};

export default data;
