import React from 'react';
import { Details } from '../../components';

export default function ({ university, faculty, graduated }) {
  return (
    <Details title="EDUCATION">
      <p className="underlined">{university}</p>
      <p>{faculty}</p>
      <p>{`Graduated in ${graduated}`}</p>
    </Details>
  );
}
