import React from 'react';

function List({ title, items }) {
  return (
    <div className="list">
      <p className="underlined">{title}</p>
      <ul>
        {
          items.map((item) => (
            <li key={item}>{item}</li>
          ))
        }
      </ul>
    </div>
  );
}

export default List;
