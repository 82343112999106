import React from 'react';

function Description({ children, className }) {
  return (
    <div className={`desc ${className}`}>
      {children}
    </div>
  );
}

export default Description;
