import React from 'react';
import { Company, Details, Project } from '../../components';

export default function ({ experience }) {
  return (
    <Details title="EXPERIENCE">
      {
        experience.map((exp) => (
          <Company key={exp.companyName} company={exp.companyName} date={exp.period}>
            {
              exp.projects.map((project) => (
                <Project key={project.name}>
                  <Project.Name>{project.name}</Project.Name>
                  <Project.Desc>{project.desc}</Project.Desc>
                  <Project.Stack>{project.stack}</Project.Stack>
                  <Project.Role>{project.role}</Project.Role>
                </Project>
              ))
            }
          </Company>
        ))
      }
    </Details>
  );
}
