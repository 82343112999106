import React from 'react';

function Project({ children }) {
  return <div className="project">{children}</div>;
}

export function ProjectName({ children }) {
  return (
    <div>
      <span className="item"> Project name:</span>
      {' '}
      <span>{children}</span>
    </div>
  );
}

export function ProjectDesc({ children }) {
  return (
    <div>
      <span className="item"> Description:</span>
      <span>{children}</span>
    </div>
  );
}

export function ProjectStack({ children }) {
  return (
    <div>
      <span className="item">Tools & technologies:</span>
      <span>{children}</span>
    </div>
  );
}

export function ProjectRole({ children }) {
  return (
    <div>
      <span className="item">Project responsibilities:</span>
      <span>{children}</span>
    </div>
  );
}

Project.Name = ProjectName;
Project.Desc = ProjectDesc;
Project.Stack = ProjectStack;
Project.Role = ProjectRole;

export default Project;
