import React from 'react';
import ReactDOM from 'react-dom/client';
import data from './data';
import './index.css';
import Title from './components/Title';
import Contacts from './sections/Contacts';
import Summary from './sections/Summary';
import Education from './sections/Education';
import Skills from './sections/Skills';
import Experience from './sections/Experience';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <div className="a4">
      <Title name={data.personal.name} />
      <div className="about">
        <Contacts {...data.personal} />
        <Summary {...data.personal} />
        <Education {...data.education} />
        <Skills {...data.skills} />
        <Experience experience={data.experience} />
      </div>
    </div>
  </React.StrictMode>,
);
