import React from 'react';

function Company({ company, date, children }) {
  return (
    <div className="exp">
      <div className="exp-main">
        <p className="underlined">{company}</p>
        <div className="time underlined">{date}</div>
      </div>
      <div className="exp-det">
        {children}
      </div>
    </div>
  );
}

export default Company;
