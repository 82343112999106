import React from 'react';
import { Details } from '../../components';

export default function ({ summary }) {
  return (
    <Details title="SUMMARY">
      <p>{summary}</p>
    </Details>
  );
}
