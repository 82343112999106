import React from 'react';
import { Details, List } from '../../components';

export default function ({ core, lang, tools }) {
  return (
    <Details title="SKILLS" className="flex">
      <div style={{ flexGrow: 1 }}>
        <List title="Core technologies:" items={core} />
        <List title="Languages:" items={lang} />
      </div>
      <List title="Tools and other technologies:" items={tools} />
    </Details>
  );
}
