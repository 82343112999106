import React from 'react';
import Title from '../Title';
import Description from '../Description';

function Details({ title, children, className }) {
  return (
    <div className="details">
      <Title title={title} />
      <Description className={className}>
        {children}
      </Description>
    </div>
  );
}

export default Details;
