import React from 'react';

function Title({ title, name }) {
  return (
    name ? <h1 className="name">{name}</h1>
      : <h2 className="title"><span>{title}</span></h2>
  );
}

export default Title;
