import React from 'react';

function Contacts({ phone, email, location }) {
  return (
    <div className="contants">
      <span>
        Phone :
        {phone}
      </span>
      <span>
        Email :
        {email}
      </span>
      <span>
        Location :
        {location}
      </span>
    </div>
  );
}

export default Contacts;
